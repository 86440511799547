import type {
  DefaultHttpRequestBody,
  HttpApiError,
} from '@backmarket/http-api/src/utils'

import { useHttpFetch } from './useHttpFetch'

type UseHttpFetchParameters<
  ResT,
  DataE = Error,
  BodyT = DefaultHttpRequestBody,
  DataT = ResT,
> = Parameters<typeof useHttpFetch<ResT, DataE, BodyT, DataT>>

/**
 * This composable provides a convenient wrapper around `useLazyAsyncData` and
 * `$httpFetch`. The difference with `useHttpFetch` is that it triggers
 * navigation while the request is still pending.
 *
 * @remarks It replaces {@link https://nuxt.com/docs/api/composables/use-lazy-fetch useLazyFetch},
 * when using `@backmarket/http-api`.
 *
 * @param endpoint The {@link HttpEndpoint endpoint} to call. See `http-api`
 * package for all possible endpoints, and/or how to declare a new endpoint.
 * @param options Additional {@link UseHttpFetchRequestOptions request options},
 * combined with {@link AsyncDataOptions async data options}
 *
 * @see {@link https://nuxt.com/docs/api/composables/use-lazy-async-data useLazyAsyncData}
 * @see {@link ../utils/$httpFetch.ts $httpFetch}
 *
 * @example
 * import { paymentAPI } from '@backmarket/http-api'
 *
 * const { data, pending, error } = await useLazyHttpFetch(paymentAPI.getMethods, {
 *   query: { country_code: 'US' },
 * })
 */

// @doc see issue https://github.com/microsoft/TypeScript/issues/36981
export function useHttpLazyFetch<
  ResT,
  DataE = HttpApiError,
  BodyT = DefaultHttpRequestBody,
  DataT = ResT,
>(
  endpoint: UseHttpFetchParameters<ResT, DataE, BodyT, DataT>[0],
  options: Omit<UseHttpFetchParameters<ResT, DataE, DataT>[1], 'lazy'> = {},
) {
  return useHttpFetch(endpoint, { ...options, lazy: true })
}
